<template>
    <div class="musicList">
        <left :left="left">
            <tabList :tabList="tabList" @leftTab="leftTab"></tabList>
        </left>
        <div class="music_left">
            <topAlbum :topAlbum="topAlbum"></topAlbum>
            <div class="list_name">专辑列表</div>
            <div class="music_album_list">
                <musicList :musicList="musicList" @btnLink="btnLink"></musicList>
            </div>
        </div>
        <loading :loading="loading"></loading>
    </div>
</template>

<script>
import {bjolor} from '../../components/js/index'
import left from '../../components/common/index/left.vue'
import tabList from './components/tabList.vue'
import topAlbum from './components/topAlbum.vue'
import musicList from './components/musicList.vue'
import api from '../../api/album'
import loading from '../../components/common/loading.vue'
export default {
    name: '',
    data() {
        return {
            left: {
                isbg: "#888",
            },
            tabList: {
                name: "所有专辑",
                bg: "",
                tabIndex:"",
                color: "#fff",
                lists: [],
            },
            topAlbum: {
                albumCover: "",
                albumName: '',
                albumTag: "",
                albumIssueTime: "",
                albumDesc: "",
                btnNub: ""
            },
            musicList: {
                list: [],
            },
            loading: {
                isShow: false
            },
        }
    },
    components: {
        left,
        tabList,
        topAlbum,
        musicList,
        loading
    },
    mounted() {
        var de = atob(this.$route.query.id); 
        this.listFun(de)
        this.tabList.tabIndex = de
    },

    methods: {
        btnLink(valid) {
            // console.log(valid);
            var en = btoa( valid.id );
            this.$router.push({ path: '/play', query: { id: en} })
        },
        listFun(id) {
            this.loading.isShow = true
            var obj1 = {
                song_album_id: id
            }
            // list
            api.getAllSongs(obj1).then((res) => {
                if (res.data.code == 200) {
                    // console.log(res.data, 9999)
                    this.musicList.list = res.data.result
                    this.loading.isShow = false
                }
            })


            // top
            api.albumId(id).then((res) => {
                if (res.data.code == 200) {
                    // console.log(res, 1111);
                    this.topAlbum = res.data.result
                    this.left.isbg =  bjolor(res.data.result.album_colors) 
                    this.loading.isShow = false
                    this.uidFun(res.data.result.album_user_id)
                }
            })
        },
        // 左面
        uidFun(uid) {
            var obj2 = {
                album_user_id: uid
            }
            this.loading.isShow = true
            api.getAlbumListAndCountById(obj2).then((res) => {
                if (res.data.code == 200) {
                    this.loading.isShow = false
                    this.tabList.lists = res.data.result
                    this.loading.isShow = false
                }
            })
        },
        leftTab(val){
            this.listFun(val.id)
        }
    }
}
</script>

<style scoped>
.music_left {
    width: 100%;
    padding-top: 60px;
    padding-left: 220px;
    margin-bottom: 70px;
    box-sizing: border-box;
}

.list_name {
    margin-bottom: -10px;
    margin-top: 12px;
    font-size: 18px;
}
</style>
