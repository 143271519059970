<template>
    <div class="all_left">
        <div class="left_name" :style="{ color: dynamicColorFun (all.color)}">{{ all.name }}</div>
        <div class="left_ul" >
            <div class="left_li" :style="{ background: all.bg }" v-for="(item, index) in all.lists" @click="btn(item)"
                :class="item.id == all.tabIndex ? 'bf' : ''">
                <div>
                    <div class="left_name_music" :style="{ color: dynamicColorFun (all.color) }"> 专辑：{{ item.album_name }}</div>
                    <div class="left_name_nub" :style="{ color: dynamicColorFun (all.color)}"> {{ item.album_song_num }}:首</div>
                </div>
                <div v-if="item.id  == all.tabIndex ? true : false" class="xons">
                    <div v-for="(son, index) in item.song_list" :key="index" class="lis">
                        <div class="imgs">
                            <imgIng v-if="son&& son.song_cover" class="play_end_image" :src=" 'http://82.157.140.72/test' + (son&& son.song_cover)" alt=""></imgIng>
                        </div>
                        <div > 
                            <div class="h1" :style="{ color: dynamicColorFun (all.color) }">{{son.song_name}}</div>
                            <div class="h1" :style="{ color: dynamicColorFun (all.color) }">{{son.song_issue_date}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {dynamicColor} from '../../../components/js/index'
import imgIng from '../../../components/common/imgIng.vue'
export default {
    name: '',
    props: {
        tabList: {
            type: Object,
            default: () => {
                return {
                    name: "",
                    lists: [],
                    bg: "",
                    color: "",
                    tabIndex:"",
                }
            }
        },
    },

    data() {
        return {
            name: "",
            lists: [],
            bg: "",
            color: "",
            tabIndex: 0,

        }
    },
    components: {
        imgIng
    },
    computed: {
        all() {
            let Obj = this.tabList
            if (!Obj.lists) {
                Obj.lists = this.lists
            }
            if (!Obj.name) {
                Obj.name = this.name
            }
            if (!Obj.tabIndex) {
                Obj.tabIndex = this.tabIndex
            }
            if (!Obj.bg) {
                Obj.bg = this.bg
            }
            if (!Obj.color) {
                Obj.color = this.color
            }

            return Obj
        }
    },

    created() {

    },
    mounted() {

    },
    methods: {
        btn(item) {
            this.all.tabIndex = item.id;
            this.$emit("leftTab" ,item)
        },
        dynamicColorFun(v){
            return dynamicColor(v)
        }

    },
}
</script>

<style scoped>
.music_left {
    width: 100%;
    padding-top: 60px;
    padding-left: 220px;
    box-sizing: border-box;
    overflow: hidden;
}

.left_name {
    color: #aaaeb9;
    margin-top: 6px;
    margin-bottom: 2px;
}

.left_ul {
    overflow: hidden;
}

.left_li {
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 4px 0px 4px 0;
    width: 98%;
    color: #aaaeb9;
    background-color: #f2f2f238;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 0 6px rgb(0 0 0 / 6%);
}

.bf {
    background: rgb(187 171 171 / 32%);
    border-radius: 4px;
}
.xons{
    width: 100%;
    margin-top: 28px;
    overflow: hidden;
    
}
.lis{
    width: 88px;
    border: 1px solid rgb(120, 117, 117);
    margin: 5px;
    float: left;
    border-radius: 4px;
    overflow: hidden;

}
.lis:nth-child(2n){
    margin-left: 0;
}

.left_name_music {
    padding-left: 4px;
    box-sizing: border-box;
    float: left;
}
.imgs{
    width: 100%;
    height: 100px;
    overflow: hidden;
}

.left_name_nub {
    float: right;
    margin-right: 5px;
}
.h1{
    width: 100%;
    color: #fff;
    line-height: 16px;
    font-size: 12px;
    overflow: hidden;
    padding: 0 0 0 3px;
    box-sizing: border-box;
}
.all_left{
    width: 100%;
    height: calc(100% - 0px);
    overflow-y: scroll;
}
</style>