
<template>
	<div class="box" v-if="all.isShow">
        <div class="loading">
            <svg version="1.2" height="300" width="600" xmlns="https://www.w3.org/2000/svg" viewport="0 0 60 60" xmlns:xlink="https://www.w3.org/1999/xlink">
                <path id="pulsar" stroke="rgba(0,155,155,1)" fill="none" stroke-width="1" stroke-linejoin="round" d="M0,90L250,90Q257,60 262,87T267,95 270,88 273,92t6,35 7,-60T290,127 297,107s2,-11 10,-10 1,1 8,-10T319,95c6,4 8,-6 10,-17s2,10 9,11h210" />
            	
			</svg>
			<div class="span">加载中</div>
		
        </div>
	</div>
</template>

<script>
	export default {
		name: 'index',
		props:{
		    loading:{
		      type: Object,
		      default:()=>{
		        return {
				  isShow:false,
		        //   tipsCallback:()=>{},         // 回调
		        }
		      }
		    },
		},
		data() {
			return {
				isShow: false,
				// tipsCallback:()=>{},         // 回调
			}
		},
			
		computed:{
			// 默认值 非空判断
			all(){
				let Obj = this.loading		
			
				if(!Obj.isShow){
				    Obj.isShow = this.isShow
				}
			
				
				return Obj
			}
		},
		mounted() {
		
		},
	
		methods: {
		
	
		}
	}
</script>


<style scoped>
	.loading {
        position: fixed;
        left: 212px;
        top: 62px;
        bottom: 62px;
        background: rgba(0,0,0,0.3);
        z-index: 9;
        right: 0;
	}
    svg{
        position: fixed;
        left: 50%;
        top: 50%;
        margin-left: -150px;
        margin-top: -100px;
		z-index: 9;
    }
	.span{
		position: fixed;
		left: 50%;
        top: 50%;
		margin-left: 120px;
		margin-top: 120px;
		font-size: 18px;
        z-index: 9;
		color: rgb(0, 255, 255);
	}
    #pulsar {
        stroke-dasharray: 1281;
        animation: dash 10s infinite linear forwards;
    }

    @keyframes dash {
        from {
            stroke-dashoffset: 814;
        }
        to {
            stroke-dashoffset: -814;
        }
    }

</style>
