<template>
    <!-- 专辑列表  -->
    <div class="music_album">
        <div class="music_album_top">
            <div class="music_album_img">
                <imgIng v-if="all.album_cover" class="music_album_image" :src=" 'http://82.157.140.72/test'  +  all.album_cover" alt=""></imgIng>
            </div>
            <div class="music_album_coment">
                <div class="music_album_title">{{ all.album_name }}</div>
                <div class="music_album_data">
                    <span>{{ all.album_tag }}</span> /
                    <span>{{ all.album_issue_date }}发行</span>
                </div>
                <div class="music_album_span">{{ all.album_desc }}</div>
                <div class="music_album_sc">关注</div>
                <div class="music_album_dz">点赞 {{all.album_play_num}}</div>
            </div>
            <div style="clear: both;"></div>
        </div>
    </div>
</template>

<script>
import imgIng from '../../../components/common/imgIng.vue'
export default {
    name: '',
    props: {
        topAlbum: {
            type: Object,
            default: () => {
                return {
                    name: "",
                    lists: [],
                    bg: "",
                    color: "",
                    btnNub:"",
                    dataAll: {}
                }
            }
        },
    },
    data() {
        return {
            name: "",
            lists: [],
            bg: "",
            color: "",
            btnNub:"",
            dataAll: {}
        }
    },
    computed: {
        all() {
            let Obj = this.topAlbum
            if (!Obj.lists) {
                Obj.lists = this.lists
            }
            if (!Obj.bg) {
                Obj.bg = this.bg
            }
            if (!Obj.color) {
                Obj.color = this.color
            }
            if (!Obj.btnNub) {
                Obj.btnNub = this.btnNub
            }
            if (!Obj.dataAll) {
                Obj.dataAll = this.dataAll
            }

            return Obj
        }
    },
    components: {
        imgIng
    },
    mounted() {

    },
    methods: {
        // 跳转 回调
        musicList(val) {

        },
    }
}
</script>
<style scoped>

.music_album_top {
    width: 100%;
    height: 270px;
    background: #fff;
    overflow: hidden;
    border-bottom: 1px solid #e2e2e2;
    box-shadow: 0 0 6px rgb(0 0 0 / 6%);
    margin-top: 1px;
}

.list_name {
    margin-bottom: -10px;
    margin-top: 12px;
    font-size: 18px;
}

.music_album_img {
    width: 250px;
    height: 250px;
    background: #020202;
    position: relative;
    z-index: 1;
    float: left;
    border: 1px solid #e7cccc;
    padding: 2px;
    box-shadow: 8px 11px 8px 1px rgb(26 24 24 / 23%);
    border-radius: 4px;
}

.music_album_image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.music_album_img::before {
    content: "";
    width: 260px;
    height: 260px;
    position: absolute;
    right: -36px;
    top: 0px;
    background-size: 100%;
    z-index: -1;
    background: url(../../../assets/download.png) 100% no-repeat;
}
.music_album_img::after {
    content: "";
    width: 258px;
    height: 245px;
    position: absolute;
    background: rgb(178 41 41 / 14%);
    left: 2px;
    z-index: -2;
    top: 6px;
    border-radius: 1px;
}

.music_album_coment {
    float: left;
    margin-left: 50px;
    margin-top: 22px;
    width: calc(100% - 400px);
}

.music_album_title {
    font-size: 30px;
}

.music_album_data {
    margin-top: 10px;
}

.music_album_span {
    height: 70px;
    margin-top: 30px;
    margin-bottom: 20px;
    overflow: hidden;
}

.music_album_sc {
    background: #eb454f;
    border-color: #eb454f;
    color: #fff;
    border-radius: 20px;
    padding: 6px 20px;
    display: initial;
    cursor: pointer;
}

.music_album_gz {
    background: #e8e4e4;
    border-color: #000000;
    color: #000;
    border-radius: 20px;
    padding: 6px 20px;
    display: initial;
    cursor: pointer;
    margin-left: 30px;
}

.music_album_dz {
    background: #e8e4e4;
    border-color: #000000;
    color: #000;
    border-radius: 20px;
    padding: 6px 20px;
    display: initial;
    cursor: pointer;
    margin-left: 30px;
}




</style>
