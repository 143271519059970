<template>
    <div>
        <div class="search_ul" v-for="(item, index) in all.list" :key="index" @click="btnLink(item)">
            <div class="search_li_img">
                <imgIng v-if="item&&item.song_cover" class="search_li_image" :src=" 'http://82.157.140.72/test' +( item&&item.song_cover)" alt=""></imgIng>
            </div>

            <div class="ovs">
                <!-- echart -->
                <!-- <canvasList></canvasList> -->
                <div class="search_li_flew" :style="{background:item.song_colors}">
                    <div class="ov">
                        <div class="search_li_z" :style="{color:color(item.song_colors)}"> {{ index+1}}</div>
                        <div class="search_li_name" :style="{color:color(item.song_colors)}"> 歌名字:{{ item.song_name }}</div>
                        <div class="search_li_time" :style="{color:color(item.song_colors)}">时间: <span :style="{color:color(item.song_colors)}">{{ item.song_issue_date }}</span></div>
                        <div class="search_li_musicname" :style="{color:color(item.song_colors)}">作者: <span :style="{color:color(item.song_colors)}">{{ item.song_author }}</span></div>
                        <div class="search_li_playnub" :style="{color:color(item.song_colors)}">播放量: <span  :style="{color:color(item.song_colors)}">{{ item.song_play_num }}</span></div>
                        <div class="search_li_btnplaynub" :style="{color:color(item.song_colors)}">点击量: <span  :style="{color:color(item.song_colors)}">{{ item.song_fav_num }}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {colorRGB} from '../../../components/js/index'
import imgIng from '../../../components/common/imgIng.vue'
// import canvasList from './canvas.vue'

export default {
    name: '',
    props: {
        musicList: {
            type: Object,
            default: () => {
                return {
                    list: [],
                 

                }
            }
        },
    },

    data() {
        return {
            list: [],

        }
    },
    components: {
        imgIng,
        // canvasList
    },
    computed: {
        all() {
            let Obj = this.musicList
            if (!Obj.list) {
                Obj.list = this.list
            }
   

            return Obj
        }
    },

    created() {

    },
    mounted() {
  
    },
    methods: {
        btnLink(val){
            this.$emit('btnLink', val)
        },
        color(v){
            return colorRGB(v)
        }

    },
}
</script>

<style scoped>
.echart {
    width: calc(100% - 40px);
    height: 60px;
    float: left;
    margin-left: 1px;
    margin-top: 0px;
    background: #e2e2e2;
}

.search_ul {
    width: 100%;
    min-height: 40px;
    border: 1px solid rgba(0, 0, 0, .12);
    margin-top: 12px;
    border-radius: 4px;
    overflow: hidden;
    padding: 1px 0px 0px 1px;
    box-sizing: border-box;
    line-height: 40px;
    cursor: pointer;
    transition: all .5s ease;
    position: relative;
    z-index: 2;
    box-shadow: 4px 3px 1px 3px rgb(199 192 192 / 23%);

}


.search_li_img {
    width: 40px;
    height: 40px;
    float: left;
}

.search_ul:hover {
    transform: scale(1.03);
    transition: all .5s ease;
    border: 1px solid rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 0px 2px rgb(199 192 192 / 90%);
}

.search_li_image {
    width: 40px;
}

.search_title {
    font-size: 32px;
    padding: 10px 0 5px 0;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, .07);
}

.search_title_son {
    width: 1200px;
    margin: 0 auto 5px;
}

.search_li_flew {
    width: calc(100% - 40px);
    height: 40px;
    float: left;
    margin-left: 2px;
    position: absolute;
    top: 0;
    left: 40px;
}

.ov {
    display: flex;
    color: #333;
}

.ovs {
    position: relative;
}

.search_li_name {
    flex: 1;
}
.search_li_z{
   width: 40px;
    padding-left: 12px;
    box-sizing: border-box;
}

.search_li_time {
    flex: 1;
}

.search_li_musicname {
    flex: 1;
}

.search_li_playnub {
    flex: 1;
}

.search_li_btnplaynub {
    flex: 1;
}
</style>