/**
 * 引入 axios  拦截器
 */
import apiService from '../utils/service'
import {token} from '../components/js/index'
/**
 *  接口 api
 */
export default {
    album(data) {
        return apiService({
            url: '/album',
            method: 'post',
            headers: {
                // authorization: ` Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`
                authorization: ` Bearer ${token.token}`
            },
            data: data
        })
    },
    albumList(data) {
        return apiService({
            url: '/album/list',
            method: 'post',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            data: data
        })
    },
    upload(data) {
        return apiService({
            url: '/base/upload',
            method: 'post',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            data: data
        })
    },

    uploadFile(data,uploadFile) {
        return apiService({
            url: '/base/uploadFile' + '?fileType='  +  uploadFile,
            method: 'post',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            data: data
        })
    },
    songs(data) {
        return apiService({
            url: '/songs',
            method: 'post',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            data: data
        })
    },
    songsLook(data) {
        return apiService({
            url: '/songs/' + data,
            method: 'get',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
        })
    },
    songsList(data) {
        return apiService({
            url: '/songs/list',
            method: 'post',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            data: data
        })
    },


    
    getAllSongs(data) {
        return apiService({
            url: '/album/getAllSongs',
            method: 'post',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            data: data
        })
    },

    getAlbumListAndCountById(data) {
        return apiService({
            url: '/album/getAlbumListAndCountById',
            method: 'get',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
            params: data
        })
    },
    albumId(data) {
        return apiService({
            url: 'album/' +data,
            method: 'get',
            headers: {
                authorization: ` Bearer ${token.token}`
            },
           
        })
    },

    

   


}

